//@ts-nocheck
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import axios from "axios";
import { useAuth } from "../context/AuthContext";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";

const API_BASE_URL = "/api";

// Utility functions
const fetchTest = async (testId, token) => {
  const response = await axios.get(`${API_BASE_URL}/tests/${testId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const submitSection = async ({
  testId,
  sectionId,
  answers,
  score,
  questionsAttempted,
  startTime,
  token,
}) => {
  const response = await axios.post(
    `${API_BASE_URL}/tests/submit/${testId}/${sectionId}`,
    { answers, score, questionsAttempted, startTime },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
};

const submitTest = async ({ testId, token }) => {
  const response = await axios.post(
    `${API_BASE_URL}/tests/submit/${testId}`,
    {},
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
};

const formatTime = (seconds) => {
  if (seconds == null) return "00:00:00";
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
};

const formatTimeDays = (ms) => {
  let totalSeconds = Math.floor(ms / 1000);
  const days = Math.floor(totalSeconds / (24 * 60 * 60));
  totalSeconds %= 24 * 60 * 60;
  const hours = Math.floor(totalSeconds / (60 * 60));
  totalSeconds %= 60 * 60;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  return [
    days,
    hours.toString().padStart(2, "0"),
    minutes.toString().padStart(2, "0"),
    seconds.toString().padStart(2, "0"),
  ].join(":");
};

// Sub-components
const CountdownTimer = ({ initialTime, onComplete }) => {
  const [timeRemaining, setTimeRemaining] = useState(initialTime);

  useEffect(() => {
    if (timeRemaining <= 0) {
      onComplete();
      return;
    }

    const timerId = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime <= 1000) {
          clearInterval(timerId);
          return 0;
        }
        return prevTime - 1000;
      });
    }, 1000);

    return () => clearInterval(timerId);
  }, [timeRemaining, onComplete]);

  return (
    <div className="text-center p-4 bg-[#151523] rounded-lg shadow">
      <h2 className="text-2xl font-bold mb-2">Test will start in:</h2>
      <p className="text-4xl font-mono">{formatTimeDays(timeRemaining)}</p>
    </div>
  );
};

const QuestionCard = React.memo(
  ({ question, onAnswerChange, selectedAnswer }) => {
    const renderQuestionText = useCallback((text) => {
      const parts = text.split(/```([\s\S]*?)```/);
      return parts.map((part, index) => {
        if (index % 2 === 1) {
          return (
            <SyntaxHighlighter
              key={index}
              language="javascript"
              style={docco}
              className="my-4 rounded-md"
            >
              {part.trim()}
            </SyntaxHighlighter>
          );
        } else {
          return <span key={index}>{part}</span>;
        }
      });
    }, []);

    const handleClearAnswer = useCallback(() => {
      onAnswerChange(null);
    }, [onAnswerChange]);

    return (
      <div className="bg-white shadow-md rounded-lg p-6">
        <h2 className="text-xl font-semibold mb-4">
          {renderQuestionText(question.question)}
        </h2>
        <div className="space-y-2">
          {question.options.map((option, index) => (
            <label
              key={index}
              className="flex items-center space-x-3 p-2 rounded-md hover:bg-[#151523] hover:bg-opacity-30 hover:text-white"
            >
              <input
                type="radio"
                name={`question-${question._id}`}
                value={option}
                checked={selectedAnswer === option}
                onChange={() => onAnswerChange(option)}
                className="form-radio h-5 w-5 text-[#fd8b9e]"
              />
              <span className="text-gray-700">{option}</span>
            </label>
          ))}
        </div>
        <div className="mt-4">
          <button
            onClick={handleClearAnswer}
            className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
          >
            Clear Answer
          </button>
        </div>
      </div>
    );
  }
);

const CameraFeed = ({ stream }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (stream && videoRef.current) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  if (!stream) {
    return (
      <div className="bg-gray-200 w-full h-48 flex items-center justify-center text-gray-500">
        Camera not available
      </div>
    );
  }

  return (
    <div className="rounded-lg overflow-hidden shadow-lg">
      <video
        ref={videoRef}
        autoPlay
        playsInline
        muted
        className="w-full h-auto"
      />
    </div>
  );
};

const InstructionsModal = ({
  onRequestPermissions,
  onStartTest,
  permissionsGranted,
}) => {
  return (
    <div className="fixed inset-0 bg-[#151523] bg-opacity-50 overflow-y-auto h-full w-full">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div className="mt-3">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Test Instructions
          </h3>
          <div className="mt-2 px-7 py-3">
            <p className=" text-gray-500">
              1. The test must be taken in full-screen mode.
            </p>
            <p className=" text-gray-500">
              2. Camera and location access are required.
            </p>
            <p className=" text-gray-500">
              3. Switching screens or exiting full-screen mode will result in
              warnings.
            </p>
            <p className=" text-gray-500">
              4. After 3 warnings, the test will be automatically submitted.
            </p>
          </div>
          <div className="items-center px-4 py-3">
            <button
              onClick={onRequestPermissions}
              className="px-4 py-2 text-[#151523] bg-[#fd8b9e]  text-base font-medium rounded-md w-full shadow-sm hover:scale-105 focus:outline-none focus:ring-2 "
            >
              Grant Permissions
            </button>
          </div>
          <div className="items-center px-4 py-3">
            <button
              onClick={onStartTest}
              disabled={!permissionsGranted}
              className={`px-4 py-2 bg-green-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300 ${
                !permissionsGranted ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              Start Test
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// Main TestPage component
const TestPage = () => {
  const { testId } = useParams();
  const { token } = useAuth();
  const navigate = useNavigate();
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [sectionStartTime, setSectionStartTime] = useState(null);
  const [overallTimeLeft, setOverallTimeLeft] = useState(null);
  const [testCompleted, setTestCompleted] = useState(false);
  const [testResults, setTestResults] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showInstructions, setShowInstructions] = useState(true);
  const [permissionsGranted, setPermissionsGranted] = useState(false);
  const [screenSwitchWarnings, setScreenSwitchWarnings] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const [stream, setStream] = useState(null);
  const [isSubmittingTest, setIsSubmittingTest] = useState(false);
  const [testStarted, setTestStarted] = useState(false);
  const watchId = useRef(null);

  const {
    data: testData,
    error,
    isLoading: isTestLoading,
  } = useQuery(["test", testId], () => fetchTest(testId, token), {
    refetchOnWindowFocus: false,
  });

  const calculateScore = (sectionAnswers, questions) => {
    let score = 0;
    let questionsAttempted = 0;

    questions.forEach((question, index) => {
      if (sectionAnswers[index] !== undefined) {
        questionsAttempted++;
        if (question.correctAnswer === sectionAnswers[index]) {
          score += 1; // +1 for correct answer
        } else {
          score -= 0.25; // -0.25 for incorrect answer
        }
      }
    });

    return { score, questionsAttempted };
  };
  const endTestAndRevokePermissions = () => {
    // Turn off the camera
    if (stream) {
      stream.getTracks().forEach((track) => {
        track.stop();
      });
      setStream(null);
    }

    // Stop watching location
    if (navigator.geolocation && watchId.current) {
      navigator.geolocation.clearWatch(watchId.current);
      watchId.current = null;
    }

    // Clear the user's location data
    setUserLocation(null);

    // Reset permissions granted state
    setPermissionsGranted(false);

    console.log("Camera and location access have been revoked.");
  };
  const sectionSubmitMutation = useMutation(submitSection, {
    onMutate: () => {
      setIsSubmitting(true);
    },
    onSuccess: (data) => {
      console.log("Section submitted successfully:", data);
      if (currentSectionIndex < testData.test.sections.length - 1) {
        setCurrentSectionIndex((prev) => prev + 1);
        setCurrentQuestionIndex(0);
        setAnswers({});
        setSectionStartTime(new Date().toISOString());
      } else {
        handleSubmitTest();
      }
    },
    onSettled: () => {
      setIsSubmitting(false);
    },
  });

  const testSubmitMutation = useMutation(submitTest, {
    onMutate: () => {
      setIsSubmittingTest(true);
    },
    onSuccess: (data) => {
      setTestCompleted(true);
      setTestResults(data.result);
    },
    onSettled: () => {
      setIsSubmittingTest(false);
    },
  });

  const handleNextQuestion = useCallback(() => {
    if (!testData || !testData.test || isSubmitting) return;

    const currentSection = testData.test.sections[currentSectionIndex];
    if (currentQuestionIndex < currentSection.questions.length - 1) {
      setCurrentQuestionIndex((prev) => prev + 1);
    } else {
      handleSubmitSection();
    }
  }, [currentSectionIndex, currentQuestionIndex, testData, isSubmitting]);

  const handleSubmitSection = useCallback(() => {
    if (!testData || !testData.test || isSubmitting) return;

    const sectionAnswers = Object.values(answers);
    const { score, questionsAttempted } = calculateScore(
      sectionAnswers,
      testData.test.sections[currentSectionIndex].questions
    );

    sectionSubmitMutation.mutate({
      testId,
      sectionId: testData.test.sections[currentSectionIndex].id,
      answers: sectionAnswers,
      score,
      questionsAttempted,
      startTime: sectionStartTime,
      token,
    });
  }, [
    testId,
    currentSectionIndex,
    answers,
    sectionStartTime,
    token,
    testData,
    sectionSubmitMutation,
    isSubmitting,
  ]);

  const handleSubmitTest = useCallback(() => {
    if (isSubmittingTest) return;
    testSubmitMutation.mutate({ testId, token });
    endTestAndRevokePermissions();
  }, [testId, token, testSubmitMutation, isSubmittingTest]);

  const handleScreenSwitch = useCallback(() => {
    setScreenSwitchWarnings((prev) => {
      const newWarnings = prev + 1;
      if (newWarnings >= 3) {
        if (currentSectionIndex === 0 && !isSubmitting) {
          handleSubmitSection();
        } else {
          handleSubmitTest();
        }
      } else {
        alert(`Warning: Screen switch detected. Warning ${newWarnings}/3`);
      }
      return newWarnings;
    });
  }, [
    currentSectionIndex,
    isSubmitting,
    handleSubmitSection,
    handleSubmitTest,
  ]);

  useEffect(() => {
    if (testData && testData.test && testData.status === "ongoing") {
      setSectionStartTime((prev) => prev || new Date().toISOString());

      const endTime = new Date(testData.test.endTime).getTime();
      const overallTimerId = setInterval(() => {
        const now = new Date().getTime();
        const timeLeft = Math.max(0, Math.floor((endTime - now) / 1000));
        setOverallTimeLeft(timeLeft);
        if (timeLeft <= 0) {
          clearInterval(overallTimerId);
          handleSubmitTest();
        }
      }, 1000);

      return () => clearInterval(overallTimerId);
    }
  }, [testData, handleSubmitTest]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        handleScreenSwitch();
      }
    };

    const handleFullScreenChange = () => {
      setIsFullScreen(!!document.fullscreenElement);
      if (!document.fullscreenElement) {
        handleScreenSwitch();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    document.addEventListener("fullscreenchange", handleFullScreenChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, [handleScreenSwitch]);

  const requestCameraPermission = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      setStream(mediaStream);
      return true;
    } catch (error) {
      console.error("Error requesting camera permission:", error);
      return false;
    }
  };

  const requestLocationPermission = async () => {
    try {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
      setUserLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
      return true;
    } catch (error) {
      console.error("Error requesting location permission:", error);
      return false;
    }
  };

  const requestPermissions = async () => {
    const cameraGranted = await requestCameraPermission();
    const locationGranted = await requestLocationPermission();

    if (cameraGranted && locationGranted) {
      setPermissionsGranted(true);
    } else {
      let errorMessage = "To start the test, please enable the following:";
      if (!cameraGranted) errorMessage += "\n- Camera access";
      if (!locationGranted) errorMessage += "\n- Location access";
      errorMessage += "\n\nPlease check your browser settings and try again.";

      alert(errorMessage);
    }
  };
  const startTest = () => {
    setShowInstructions(false);
    setTestStarted(true);
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      // Firefox
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      // Chrome, Safari and Opera
      document.documentElement.webkitRequestFullscreen();
    } else if (document.documentElement.msRequestFullscreen) {
      // IE/Edge
      document.documentElement.msRequestFullscreen();
    }
  };

  if (isTestLoading)
    return <div className="text-center mt-8">Loading test...</div>;
  if (error)
    return (
      <div className="text-center mt-8 text-red-500">
        Error loading test: {error.message}
      </div>
    );
  if (!testData || !testData)
    return <div className="text-center mt-8">No test data available.</div>;

  const { test } = testData;

  if (testData.status === "waiting" && !testStarted) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CountdownTimer
          initialTime={testData.timeToStart}
          onComplete={() => setTestStarted(true)}
        />
      </div>
    );
  }

  if (testData.status === "ended") {
    return <div className="text-center mt-8">This test has ended.</div>;
  }

  if (testData.status === "completed" || testCompleted) {
    return <TestResults results={testResults || testData.result} />;
  }

  if (showInstructions || !testStarted) {
    return (
      <InstructionsModal
        onRequestPermissions={requestPermissions}
        onStartTest={startTest}
        permissionsGranted={permissionsGranted}
      />
    );
  }

  const currentSection = test.sections[currentSectionIndex];
  const currentQuestion = currentSection.questions[currentQuestionIndex];

  if (!isFullScreen) {
    return (
      <div className="fixed inset-0 bg-gray-800 text-white flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-4">Full Screen Required</h2>
          <p className="mb-4">
            Please return to full-screen mode to continue the test.
          </p>
          <p className="mb-4">Warnings: {screenSwitchWarnings}/3</p>
          <button
            onClick={startTest}
            className="bg-blue-500 hover:bg-[#fd8b9e] text-white font-bold py-2 px-4 rounded"
          >
            Enter Full Screen
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#151523]">
      <header className="bg-[#151523] text-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex flex-col items-center">
          <div className="flex justify-center items-center">
            <img
              src="https://sqilco.com/sqilco.svg"
              alt="Sqilco"
              className="w-[100px] h-auto"
            />
          </div>
          <h1 className="text-3xl font-bold  mb-2">{test.title}</h1>
          <div className="text-xl font-semibold">
            Time Left: {formatTime(overallTimeLeft)}
          </div>
        </div>
      </header>
      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-6 sm:px-0">
          <div className="mb-4 text-white">
            <h2 className="text-2xl font-semibold">
              Section: {currentSection.title}
            </h2>
            <p>
              Question {currentQuestionIndex + 1} of{" "}
              {currentSection.questions.length}
            </p>
          </div>
          {currentQuestion && (
            <QuestionCard
              question={currentQuestion}
              onAnswerChange={(answer) =>
                setAnswers((prev) => ({
                  ...prev,
                  [currentQuestionIndex]: answer,
                }))
              }
              selectedAnswer={answers[currentQuestionIndex]}
            />
          )}
          <div className="mt-6 flex justify-end">
            <button
              onClick={handleNextQuestion}
              disabled={isSubmitting}
              className={`px-4 py-2 text-[#151523] bg-[#fd8b9e] rounded-md hover:scale-105 ${
                isSubmitting ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {isSubmitting ? (
                <span>Loading...</span>
              ) : currentQuestionIndex ===
                currentSection.questions.length - 1 ? (
                currentSectionIndex === test.sections.length - 1 ? (
                  "Submit Test"
                ) : (
                  "Next Section"
                )
              ) : (
                "Next Question"
              )}
            </button>
          </div>
        </div>
      </main>
      <div className="fixed top-4 right-4 w-64">
        <CameraFeed stream={stream} />
      </div>
    </div>
  );
};

// TestResults component
const TestResults = ({ results }) => {
  const navigate = useNavigate();

  if (!results || !results.sections) {
    return <div className="text-center mt-8">No results available.</div>;
  }

  return (
    <div className="min-h-screen bg-[#151523] py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <div className="bg-white shadow-lg rounded-lg p-8">
          <h2 className="text-3xl font-bold text-center mb-8">Test Results</h2>
          <div className="text-center mb-8">
            <p className="text-4xl font-bold text-[#fd8b9e]">
              {results.totalScore.toFixed(2)} / {results.totalPossibleScore}
            </p>
            <p className="text-xl text-gray-600 mt-2">Total Score</p>
          </div>
          <div className="space-y-4">
            {results.sections.map((section, index) => (
              <div key={index} className="p-4 rounded-md bg-gray-50">
                <p className="font-semibold">{section.sectionName}</p>
                <p className="mt-2">
                  Score: {section.score.toFixed(2)} /{" "}
                  {section.totalPossibleScore}
                </p>
                <p className="mt-1">
                  Questions Attempted: {section.questionsAttempted} /{" "}
                  {section.totalQuestions}
                </p>
              </div>
            ))}
          </div>
          <div className="mt-8 text-center">
            <a
              href="https://sqilco.com/"
              className="px-6 py-2 bg-[#fd8b9e] text-white rounded-md hover:scale-105"
            >
              Redirect To Sqilco
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestPage;
