// AuthContext.js
import React, { createContext, useContext, useState, useCallback } from "react";
import axios from "axios";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [tempEmail, setTempEmail] = useState(null);

  const login = useCallback(async (email, password) => {
    try {
      const response = await axios.post("/api/auth/login", {
        email,
        password,
      });
      setUser(response.data.user);
      setToken(response.data.token);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || "Login failed");
    }
  }, []);

  const register = useCallback(async (userData) => {
    try {
      const response = await axios.post("/api/auth/register", userData);
      setTempEmail(userData.email);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || "Registration failed");
    }
  }, []);

  const verifyEmail = useCallback(async (email, otp) => {
    try {
      const response = await axios.post("/api/auth/verify-email", {
        email,
        otp,
      });
      setUser(response.data.user);
      setToken(response.data.token);
      setTempEmail(null);
      return response.data;
    } catch (error) {
      throw new Error(
        error.response?.data?.message || "Email verification failed"
      );
    }
  }, []);

  const resendOTP = useCallback(async (email) => {
    try {
      const response = await axios.post("/api/auth/resend-otp", { email });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || "OTP resend failed");
    }
  }, []);

  const logout = useCallback(() => {
    setUser(null);
    setToken(null);
    setTempEmail(null);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        tempEmail,
        login,
        register,
        verifyEmail,
        resendOTP,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
