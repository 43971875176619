// Register.js
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAuth } from "../context/AuthContext";

const Register = () => {
  const {
    register: registerUser,
    verifyEmail,
    resendOTP,
    tempEmail,
  } = useAuth();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");

  const onSubmit = async (data) => {
    try {
      await registerUser(data);
      setIsOtpSent(true);
    } catch (error) {
      console.error("Registration error:", error);
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    try {
      await verifyEmail(tempEmail, otp);
      navigate("/tests");
    } catch (error) {
      setOtpError("Invalid OTP. Please try again.");
    }
  };

  const handleResendOtp = async () => {
    try {
      await resendOTP(tempEmail);
      setOtpError("");
    } catch (error) {
      setOtpError("Failed to resend OTP. Please try again later.");
    }
  };

  if (isOtpSent) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-[#151523]">
        <div className="px-8 py-6 w-full md:w-1/3  w-full md:w-1/3 mt-4 text-left bg-white shadow-lg sm:rounded-lg">
          <h3 className="text-2xl font-bold text-center">Verify Your Email</h3>
          <form onSubmit={handleOtpSubmit} className="mt-4">
            <div>
              <label className="block" htmlFor="otp">
                Enter OTP
              </label>
              <input
                type="text"
                placeholder="Enter the OTP sent to your email"
                className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-[#151523]"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
              />
              {otpError && (
                <p className="text-red-500 text-xs mt-1">{otpError}</p>
              )}
            </div>
            <div className="flex items-baseline justify-between mt-4">
              <button
                type="submit"
                className="px-6 py-2 text-[#fd8b9e] bg-[#151523] rounded-lg hover:scale-105 focus:outline-none focus:ring-2 focus:ring-[#151523] focus:ring-opacity-50"
              >
                Verify OTP
              </button>
              <button
                type="button"
                onClick={handleResendOtp}
                className="text-sm text-[#fd8b9e] bg-transparent hover:underline hover:scale-105"
              >
                Resend OTP
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-[#151523]">
      <div className="px-8 py-6 w-full md:w-1/3  mt-4 text-left bg-white shadow-lg sm:rounded-lg">
        <div className="flex justify-center items-center">
          {" "}
          <img
            src="https://sqilco.com/sqilco.svg"
            alt="Sqilco"
            className="w-[30%]"
          />
        </div>
        <h3 className="text-2xl font-bold text-center">Register an Account</h3>

        <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
          <div>
            <label className="block" htmlFor="fullName">
              Full Name
            </label>
            <input
              type="text"
              placeholder="Enter your full name"
              className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-[#151523]"
              {...register("fullName", { required: "Full name is required" })}
            />
            {errors.fullName && (
              <p className="text-red-500 text-xs mt-1">
                {errors.fullName.message}
              </p>
            )}
          </div>
          <div className="mt-4">
            <label className="block" htmlFor="phoneNumber">
              Phone Number
            </label>
            <input
              type="tel"
              placeholder="Enter your phone number"
              className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-[#151523]"
              {...register("phoneNumber", {
                required: "Phone number is required",
              })}
            />
            {errors.phoneNumber && (
              <p className="text-red-500 text-xs mt-1">
                {errors.phoneNumber.message}
              </p>
            )}
          </div>
          <div className="mt-4">
            <label className="block" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              placeholder="Enter your email"
              className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-[#151523]"
              {...register("email", { required: "Email is required" })}
            />
            {errors.email && (
              <p className="text-red-500 text-xs mt-1">
                {errors.email.message}
              </p>
            )}
          </div>
          <div className="mt-4">
            <label className="block" htmlFor="collegeCode">
              College Code
            </label>
            <input
              type="text"
              placeholder="Enter your college code"
              className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-[#151523]"
              {...register("collegeCode", {
                required: "College code is required",
              })}
            />
            {errors.collegeCode && (
              <p className="text-red-500 text-xs mt-1">
                {errors.collegeCode.message}
              </p>
            )}
          </div>
          <div className="mt-4">
            <label className="block" htmlFor="collegeRollNo">
              College Roll Number
            </label>
            <input
              type="text"
              placeholder="Enter your college roll number"
              className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-[#151523]"
              {...register("collegeRollNo", {
                required: "College roll number is required",
              })}
            />
            {errors.collegeRollNo && (
              <p className="text-red-500 text-xs mt-1">
                {errors.collegeRollNo.message}
              </p>
            )}
          </div>
          <div className="mt-4">
            <label className="block" htmlFor="degree">
              Degree
            </label>
            <input
              type="text"
              placeholder="Enter your degree"
              className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-[#151523]"
              {...register("degree", { required: "Degree is required" })}
            />
            {errors.degree && (
              <p className="text-red-500 text-xs mt-1">
                {errors.degree.message}
              </p>
            )}
          </div>
          <div className="mt-4">
            <label className="block" htmlFor="currentYear">
              Current Year
            </label>
            <input
              type="number"
              placeholder="Enter your current year"
              className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-[#151523]"
              {...register("currentYear", {
                required: "Current year is required",
                min: 1,
              })}
            />
            {errors.currentYear && (
              <p className="text-red-500 text-xs mt-1">
                {errors.currentYear.message}
              </p>
            )}
          </div>
          <div className="mt-4">
            <label className="block">Password</label>
            <input
              type="password"
              placeholder="Enter your password"
              className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-[#151523]"
              {...register("password", { required: "Password is required" })}
            />
            {errors.password && (
              <p className="text-red-500 text-xs mt-1">
                {errors.password.message}
              </p>
            )}
          </div>
          <div className="flex items-baseline justify-between mt-4">
            <button className="px-6 py-2 text-black bg-[#fd8b9e] rounded-lg hover:scale-105 focus:outline-none focus:ring-2 focus:ring-[#151523] focus:ring-opacity-50">
              Register
            </button>
            <Link
              to="/login"
              className="text-sm text-[#fd8b9e] hover:underline"
            >
              Login
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
